:root {
  --premier-color: #023e8a;
  --second-color: #0f0;
  --third-color: #023e8a;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

h2,.headerText{
  font-family: Open Sans !important;

}
.espaceSection{
  margin-top: 6%;
  margin-bottom: 6%;
}
.dangerLigne{
  border-bottom: 5px solid #ff0000;
  width: 10%;
  
}
.firs_section_home {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32.6rem;

  flex-shrink: 0;
  background-image: url("./../../assets/images/background/background1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
p{
  font-family: Plus Jakarta Sans !important;

}
.mb-5{
  margin-bottom: 5rem !important;
}
.navbar-brand img {
  width: 12.3125rem;
}
.navHome a {
  color: #023e8a;
  font-family: Open Sans;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  /* font-weight: 600; */
  line-height: normal;
}
.navHome .collapse ul {
  border: 1px solid #023e8a;
  border-radius: 18.75rem;
}
#languageDropdown{
  background: #023e8a;
  border-radius: 18.75rem;
  color: white !important;
  font-family: Dashing !important;


}
#languageDropdown button{
  font-family: Dashing !important;

}

.navHome .active {
  color: white !important;
  display: flex;
  width: 6.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 18.75rem;
  background: #023e8a;
}

/* .swiper-button-prev,
.swiper-button-next {
  margin: 10px;
} */

/* .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.contact-form {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-direction: column;
  border-radius: 1.25rem;
  background: #fff;
  /* border: #599df7 3px dotted; */
}
.contact-form h2 {
  color: #023e8a;

  font-family: Plus Jakarta Sans;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.05rem;
}
.copyRight {
  color: #000;

  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.03375rem;
}
#navbarNavDropdown {
  position: relative;
  left: -5rem;
}
.footer {
  overflow: hidden !important;
  width: 100%;
  border-top: #023e8a 2px solid;
}
.footer .backgroun_div {
  position: relative;
  display: block !important;
  /* width: 100%; */
  /* min-height: 100% !important; */
  height: 37.6rem;
  background-image: url("./../../assets/images/background/Background2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.footer .child_div h2 {
  color: #023e8a;

  font-family: Plus Jakarta Sans;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.04375rem;
}
.footer .child_div p,
.footer .child_div a {
  color: #023e8a;
  text-decoration: none;

  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.03125rem;
}
.footer .child_div img {
  width: 15%;
}
.footer .child_div {
  border-radius: 1.875rem 0rem 0rem 1.875rem;

  position: absolute;
  padding: 5%;
  top: 34%;
  right: 0px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: start;
}
.contact-div {
  position: relative;
}
.footer .center_eleps {
  position: absolute;
  top: 40%;
  right: -7rem;
  width: 13rem;
}
.navHome .dropdown-menu {
  position: absolute;
  width: 100%;
  min-width: fit-content;
}
.footer .top_left_eleps {
  position: absolute;
  top: 0%;
  left: -7rem;
  width: 13rem;
}

.footer .bottom_left_eleps {
  position: absolute;
  bottom: -7rem;
  left: -7rem;
  width: 13rem;
}

/* .swiper-container {
  width: 480px;
} */

/* @media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
} */
#dropdownMenuButton {
  color: #fff;

  text-align: center;
  font-family: Open Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 1.875rem !important;
  background: #023e8a !important;
}
/* .dropdown-menu[data-bs-popper] {
  left: initial;
  right: 0;
} */
@media (max-width: 767px) {
  .contact_div{
    width: 100% !important;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .copyRight  {
    font-size: 0.7rem ;
  }
  .backgroun_div {
    height: 18rem !important;
  }
  .backgroun_div .child_div {
    top: 20% !important;
    padding: 2% !important;
  }
  .backgroun_div .child_div h2 {
    font-size: 1rem !important;
  }
  .backgroun_div .child_div p,.backgroun_div .child_div a {
    font-size: 0.8rem !important;
  }
  .SocialMedia img{
    width: 10%;
  }
  .footer{
    flex-direction: column;
  }
  /* .footer {
    width: 100% !important;
  } */
  /* #navbarNavDropdown {
    position: initial;
    left: unset;
  }
  .navHome .collapse ul {
    border: none;
  }
  #navbarNavDropdown li:has(> a.active) {
    display: flex;
    justify-content: center;
  } */
}
/* CustomSwiper.css */

/* Default styles for larger screens */
.swiper-container {
  width: 100%;
}
@media (max-width: 990px) {
  .button_transaltes{
    display: none;
  }
  #navbarNavDropdown {
    position: initial;
    left: unset;
  }
  .navHome .collapse ul {
    border: none;
  }
  #navbarNavDropdown li:has(> a.active) {
    display: flex;
    justify-content: center;
  }
  #dropDowantranslate{
    display: block !important;
  }
  #dropDowantranslate a{
    all: unset;
  }
  #dropDowantranslate ul{
    background:unset !important
  }
  .ligne_image{
    width: 15vh;
  }
}

/* Media query for smaller screens (e.g., mobile) */
