.firs_section_home {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32.6rem;

  flex-shrink: 0;
  background-image: url("./../../assets/images/background/background1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.firs_section_home h2 {
  color: #023e8a;
  width: 45%;
  text-align: center;
  font-family: Open Sans !important;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.39375rem;
}
.section_two_home h2 {
  color: #023e8a;

  text-align: center;
  font-family: Open Sans;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.section_two_home .myCard {
  width: 16.125rem;
  height: 13.875rem;
  flex-shrink: 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  background: #023e8a;
  box-shadow: 3px 8px 28.4px 9px rgba(0, 0, 0, 0.09);
}
.section_two_home .myCard img {
  width: 30%;
}
.section_two_home .myCard p {
  color: #fff;

  text-align: center;
  font-size: 1.3625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.section_two_home .myCard span {
  color: #fff;
  text-align: center;
  font-family: AXIS;
  font-size: 1.8125rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.03625rem;
}
.section_two_home .listCard {
  display: flex;
  justify-content: center;
  position: relative;
  top: -7rem;
}
.section_two_home {
  position: relative;
}
.section_two_home label {
  border-bottom: 5px solid #ff0000;
}
.section_two_image {
  position: absolute;
  left: 3rem;
  top: 8rem;
  width: 8%;
}
.section_two_image_circles{
  position: absolute;
  right: 0%;
  top: 8rem;
  width: 8%;
}
.section_tree_home {
  margin-top: 5% !important;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  background-image: url("./../../assets/images/background/Background3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.section_tree_home h2 {
  width: 45%;
  color: #fff;
  text-align: center;
  font-family: Bageo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 3.75rem */
}
.section_tree_home .liste_card div img {
  width: 2rem;
}
.section_tree_home .liste_card div {
  margin-bottom: 5%;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border-radius: 15px;
  padding: 2%;
  align-items: center;
  background-color: #fff;
  display: flex;
}
.section_tree_home .liste_card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.section_tree_home .liste_card p {
  color: #fff;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 3.75rem */
  letter-spacing: -0.05rem;
}
.section_tree_home .icons {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}
.ligne_image {
  position: relative;
  top: -1rem;
  width: 10rem;
}
.section_four_home {
  margin-top: 3%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.section_four_home h2,
.section_five_home h2 {
  color: #023e8a;

  text-align: center;
  font-family: Open Sans;
  font-size: 2.625rem;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.10313rem;
  text-transform: uppercase;
}
.section_four_home .CustomLink {
  color: rgba(2, 62, 138, 0.5);

  font-family: Bageo;
  text-decoration: none;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.05625rem;
}
.first_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.first_div div {
  width: 7rem;
  height: 3px;
  border-radius: 100px;
  background-color: #ff0000;
}
.section_tree_home .first_div div{
  background-color: #ffffff;

}
.section_five_home {
  margin-top: 3%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
/* .section_five_home h2 {
  color: #023e8a;

  font-feature-settings: "clig" off, "liga" off;
  font-family: Bageo;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1875rem;
} */
/* .contact_section_home {
  position: relative;
} */

/* .contact_section_home .second_div {
  position: relative;
  height: 32, 3rem;
  background-image: url("./../../assets/images/background/Background2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
} */
/* .contact_section_home .child_div h2 {
  color: #023e8a;

  font-family: Plus Jakarta Sans;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; 
  letter-spacing: -0.04375rem;
} */
/* .contact_section_home .child_div p,
.contact_section_home .child_div a {
  color: #023e8a;
  text-decoration: none;

  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.03125rem;
} */
/* .contact_section_home .child_div img {
  width: 15%;
} */
/* .contact_section_home .child_div {
  border-radius: 1.875rem 0rem 0rem 1.875rem;

  position: absolute;
  padding: 5%;
  top: 30%;
  right: 0px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: start;
} */
@media (max-width: 1300px) {
  .section_tree_home h2 {
    width: 100%;
    font-size: 1rem;
  }
  .section_tree_home .liste_card p {
    font-size: 1rem;
  }
  .section_tree_home .liste_card div {
    height: 3rem;
    width: 3rem;
  }
  .section_tree_home .liste_card div img {
    width: 1.5rem;
  }
  .icons {
    display: flex;
    /* flex-direction: row; */
    /* align-items: center; */
    justify-content: space-between;
    text-align: center;
  }

  .liste_card {
    margin-bottom: 10px;
  }

  .ligne_image {
    width: 20vh;
    position: relative;
    top: -1rem;
  }
}

.cardSwiper {
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.25);
}
.cardSwiper h6 {
  color: #023e8a;

  text-align: center;
  font-family: Bageo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 767px) {
  .row,
  .container {
    margin-right: 0px !important;
  }

  .section_two_home .myCard img {
    width: 15%;
  }
  h2 {
    font-size: 1.3rem !important;
    width: 100% !important;
  }
  .section_two_home .myCard span {
    font-size: 0.8em;
  }
  .section_two_home .myCard {
    width: 6.125rem;
    height: 3.875rem;
  }
  .section_two_home .myCard p {
    font-size: 0.5rem;
    font-weight: normal;
    margin-bottom: 0px;
  }
  .section_tree_home h2 {
    width: 100%;
    font-size: 1rem;
  }
  .section_tree_home .liste_card p {
    font-size: 0.8rem;
  }
  .section_tree_home .liste_card div {
    height: 3rem;
    width: 3rem;
  }
  .section_tree_home .liste_card div img {
    width: 1.5rem;
  }
  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }

  .liste_card {
    margin-bottom: 10px;
  }

  .ligne_image {
    display: none; /* hide the line on mobile */
  }
  .primary_div {
    flex-direction: column;
  }
  .from_dive div,
  .from_dive {
    min-width: 100% !important;
    width: 100% !important;
  }

  .nav-tabs,
  #myTab {
    gap: 0rem !important;
    display: flex;
    justify-content: space-around;
  }
  /* .contact_section_home .second_div {
    background: none;
    width: 100% !important;
  }
  .contact_section_home .second_div .child_div {
    width: 100% !important;
  }
  .contact_section_home .child_div h2 {
    width: 100% !important;
  } */
}
.nav-tabs button {
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 2.34375rem */
  letter-spacing: -0.03125rem;
  color: #1a202c;
}
.nav-tabs .active {
  color: #023e8a !important;
  font-weight: 600 !important;
}
.section_four_home .text1 {
  color: #1c3988;
  font-family: Open Sans;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.section_four_home,
.section_five_home {
  position: relative;
}
.section_four_home .points {
  position: absolute;
  top: 0%;
  left: 1%;
  /* right: -7rem; */
  width: 6%;
}
.section_four_home .eleps_8 {
  position: absolute;
  bottom: 8%;
  left: 0%;
  /* right: -7rem; */
  width: 6%;
}
.section_four_home .eleps {
  position: absolute;
  top: 8%;
  right: 0%;
  /* right: -7rem; */
  width: 6%;
}
.section_five_home .section_five_image {
  position: absolute;
  top: 5%;
  right: 5%;
  /* right: -7rem; */
  width: 8%;
}
@media (max-width: 990px) {
  #myTab{
    justify-content: center !important;
  }
}