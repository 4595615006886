.second_section_about h2,.FourthSectionAbout h2,.SeventhSectionAbout h2{
    color: #023E8A !important;
text-align: center;
font-family: Open Sans;
font-size: 35px !important;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.SeventhSectionAbout h3{
    color: #023E8A;

text-align: start;
font-family: Open Sans;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
border-bottom: 3px solid #F00;
}
.second_section_about .firstP{
    color: #023E8A;

    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.second_section_about{
    position: relative;
}
.second_section_about .img{
    position: absolute;
    width: 8%;
}
.second_section_about .eleps{
    right: 10% ;
    bottom: 0%;
}
.second_section_about .eleps2{
    left: 5% ;
}
.thirdSection_about{
    position: relative;
}
/* .thirdSection_about .img-fluid{
    width: 100%;
    height: 100%;
} */
.thirdSection_about p{
    padding: 5%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* height: 35%; */
        border: #023E8A 1px solid ;
}
.thirdSection_about .background{
    background-image: url('../../assets/images/background/backgroundAbout.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100%;
    border-radius: 20px;
 

}
.SeventhSectionAbout {
    padding: 10%;
}
  .thirdSection_about .icon{
    position: absolute;
    right: 0%;
    width: 8%;
    bottom: 0%;
  }
  .FourthSectionAbout{
    margin-top: 6%;
    margin-bottom: 6%;
    background-image: url('../../assets/images/background/backgroundAbout2.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 30rem;
  }
  .FourthSectionAbout h2{
    color: white !important;
  }
.FifthSectionAbout{
display: flex;
justify-content: center;
gap: 5%;
}

.FifthSectionAbout i{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
 /* equal-height-col */
 .FifthSectionAbout p{
    color: #023E8A;
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 45px */
letter-spacing: -0.6px;
 }
 .SixthSectionAbout{
    background-image: url("../../assets/images/background/BackgroundAbout3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
 }
 .SixthSectionAbout .myCard {
    width: 18.125rem;
    height: 16.875rem;
    flex-shrink: 0;
    display: flex;
    padding: 3%;
    gap: 5%;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    background: white;
    box-shadow: 3px 8px 28.4px 9px rgba(0, 0, 0, 0.09);
  }
  .SixthSectionAbout .myCard img {
    width: 30%;
  }
  .SixthSectionAbout .myCard p {
    color: black;
  
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .SixthSectionAbout .myCard span {
    color: #023E8A;
    text-align: center;
    font-family: AXIS;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.03625rem;
  }
 .SixthSectionAbout .listCard {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 10%;
   
  }
  .aboutSectionHistoire{
    gap: 10%;
  }
  .aboutSectionHistoire img{
    width: 100%;
  }
  @media (max-width: 1200px) {
    .SixthSectionAbout  {
      gap: 2%;
    }
    .thirdSection_about .background{
        background-size: contain;
    }
    .thirdSection_about .background {
        height: auto;
    }
    .thirdSection_about{
        height: auto;
    }
    .thirdSection_about .listP{
        gap: 1rem !important;
    }
  }
  @media (max-width: 990px) {
    .thirdSection_about {
        flex-direction: column;
    }
    .listP{
        width: 100% !important;
    }
    .thirdSection_about .background{
        width: 100%;
        height: 50vh;
        background-size: cover;
    }
    .FifthSectionAbout img {
        width: 50%;
    }
    .FifthSectionAbout p{
        font-size: 20px;
    }
    .SixthSectionAbout .listCard {
        position: unset;

    }
    .SixthSectionAbout .myCard {
        width: 15rem;
        height: 13rem;
    }
    .SixthSectionAbout .myCard p{
        font-size: small;
    }
    .SixthSectionAbout .myCard span{
        font-size:medium;
    }
    .SixthSectionAbout .myCard img{
        width: 20%;
    }
}
@media (max-width: 780px) {
    .SixthSectionAbout  .listCard {
        flex-direction: column;
    }
    .SixthSectionAbout  .listCard  .myCard {
        width: 100%;
    }
    .SixthSectionAbout{
        height: auto;
        padding: 10%;
    }
    .SixthSectionAbout .myCard img{
        width: 10%;
    }
    .SixthSectionAbout .myCard p{
        font-size: medium;
    }
    .second_section_about .firstP{
        width: 100% !important;
        padding: 5%;
    }
    .FifthSectionAbout{
        flex-direction: column;
        
        gap: 10% !important;
    }
       
    .FifthSectionAbout img{
        width: 25%;
    }
}