
.customInputs,
.customInputs:focus {
  border-top: 0px;
  border-right: 0px;
  outline: none;
  border-left: 0px;
  width: 100%;
  color: #023e8a;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 9, 375rem !important;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;
  border-bottom: 1px solid #023e8a;
}
.customInputs::placeholder {
  color: #023e8a;

  font-family: Plus Jakarta Sans;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 150%;
}
.sub {
  border-radius: 0.9375rem;
  background: #023e8a !important;
  color: white !important;
}
